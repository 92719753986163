@import 'src/utils/utils';

.counter {
  @include zero;
  @include Text-14-reg;
  margin-right: 16px;
  margin-bottom: 18px;
  margin-left: 16px;
  color: $Main-1;

  @media(min-width: $lg) {
    margin-right: 0;
    margin-bottom: 30px;
    margin-left: 0;
  }
  @media(min-width: $xl) {
    @include box;
  }
}